var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mapbox-gl-control filter-control",style:([_vm.offsetStyle, _vm.posStyle])},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"left":_vm.attachLeft,"offset-x":"","z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"left":_vm.attachLeft,"right":!_vm.attachLeft},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-2",attrs:{"height":"auto","min-width":"0","small":"","disabled":!_vm.allowedActive,"color":_vm.selectedAnySome ? 'primary darken-1' : 'white'},on:{"click":_vm.initLabels}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("$vuetify.icons.faFilter")])],1)]}}],null,true)},[_c('span',[_vm._t("tooltip",function(){return [_vm._v("Filters")]})],2)])]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-card-text',[_vm._t("default"),_c('v-layout',{style:(_vm.layoutStyle),attrs:{"d-block":""}},[_vm._l((_vm.filters),function(ref,i){
var id = ref.id;
var label = ref.label;
return [_c('v-autocomplete',{key:id,attrs:{"color":"primary darken-1","item-color":"primary darken-1","dense":"","chips":"","small-chips":"","multiple":"","hide-details":"","outlined":"","clearable":"","disabled":!_vm.filterOptions[id].length,"label":label,"items":_vm.filterOptions[id],"value":_vm.selectedFilters[id],"search-input":_vm.queryText},on:{"change":function (newValue) { return _vm.updateSelectedFilters(id, newValue); }},scopedSlots:_vm._u([(_vm.filterOptions[id].length > 1 && !_vm.queryText)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function () { return _vm.toggleFilters(id); }}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedFilters[id].length > 0 ? 'primary darken-1' : ''}},[_vm._v(_vm._s(_vm.icon[id]))])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.selectedAll[id])?_c('span',[_vm._v("Deselect All")]):_c('span',[_vm._v("Select All")])])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var index = ref.index;
return [(index < _vm.numShownChips)?_c('v-chip',{attrs:{"color":parent.itemColor,"small":parent.smallChips}},[_c('span',{staticClass:"ellipsis-chip-label"},[_vm._v(_vm._s(item.text))]),(parent.deletableChips)?_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("cancel")]):_vm._e()],1):_vm._e(),(index === (_vm.numShownChips + 1))?_c('span',{staticClass:"grey--text text-caption mr-1"},[_vm._v("(+"+_vm._s(_vm.selectedFilters[id].length - _vm.numShownChips)+" others)")]):_vm._e()]}}],null,true)}),(i < _vm.filters.length - 1 && _vm.filterOptions[id].length)?_c('v-divider',{key:i,staticClass:"mt-3 mb-4"}):_vm._e()]})],2)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }