<template>
  <div class="mapbox-gl-control filter-control"
       :style="[offsetStyle, posStyle]">
    <v-menu v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            :left="attachLeft"
            offset-x
            z-index="10">
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn class="pa-1 ma-2"
              height="auto"
              min-width="0"
              small
              :disabled="!allowedActive"
              :color="selectedAnySome ? 'primary darken-1' : 'white'"
              v-on="{ ...tooltip, ...menu }"
              @click="initLabels"
            >
              <v-icon>$vuetify.icons.faFilter</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Filters</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined
              flat>
        <v-card-text>
          <slot />
          <v-layout :style="layoutStyle" d-block>
            <template v-for="({id, label}, i) in filters">
              <v-autocomplete
                color="primary darken-1"
                item-color="primary darken-1"
                dense
                chips
                small-chips
                multiple
                hide-details
                outlined
                clearable
                :disabled="!filterOptions[id].length"
                :key="id"
                :label="label"
                :items="filterOptions[id]"
                :value="selectedFilters[id]"
                :search-input="queryText"
                @change="(newValue) => updateSelectedFilters(id, newValue)"
              >
                <!-- 'Select all' item -->
                <template #prepend-item
                          v-if="filterOptions[id].length > 1 && !queryText">
                  <v-list-item ripple
                              @click="() => toggleFilters(id)">
                    <v-list-item-action>
                      <v-icon :color="selectedFilters[id].length > 0 ? 'primary darken-1' : ''">{{ icon[id] }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="selectedAll[id]">Deselect All</span>
                        <span v-else>Select All</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

                <!-- Custom selection -->
                <template #selection="{ parent, item, index }">
                  <!-- Custom chip -->
                  <v-chip v-if="index < numShownChips"
                          :color="parent.itemColor"
                          :small="parent.smallChips">
                    <span class="ellipsis-chip-label">{{ item.text }}</span>
                    <v-icon v-if="parent.deletableChips"
                            small
                            right
                            @click="parent.selectItem(item)">cancel</v-icon>
                  </v-chip>

                  <!-- Appending text -->
                  <span v-if="index === (numShownChips + 1)"
                        class="grey--text text-caption mr-1">(+{{ selectedFilters[id].length - numShownChips }} others)</span>
                </template>
              </v-autocomplete>

              <!-- Divider -->
              <v-divider v-if="i < filters.length - 1 && filterOptions[id].length" :key="i" class="mt-3 mb-4" />
            </template>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import mapboxDataMixin from '@/utils/mixins/mapbox/mapboxData.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import mapboxFiltersMixin from '@/utils/mixins/mapbox/mapboxFilters.mixin.js'
import acFilterMixin from '@/utils/mixins/filters/autocompleteFilter.mixin.js'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'pibot-mapbox-filter-control',
  mixins: [positionStyleMixin, offsetStyleMixin, menuCardStyleMixin, mapboxDataMixin, mapboxLazyMixin, mapboxFiltersMixin, acFilterMixin],
  props: {
    filters: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    btnIcon: {
      type: String,
      default: 'filter_list'
    }
  },
  data () {
    return {
      initialized: false,
      menu: false,
      labelsInitialized: false,
      unwatchLayers: null,
      selectedFilters: {},
      layerIds: null,
      sourceIds: null,
      numShownChips: 2
    }
  },
  computed: {
    ...mapState({
      dataPending: state => state.mapbox.dataPending,
      layersPending: state => state.mapbox.layersPending,
      filterRefs: state => state.mapbox.filterRefs
    }),
    label () {
      if (this.$slots.tooltip) return this.$slots.tooltip[0].text
      return 'Select filters'
    },
    filterOptions () {
      const options = {}

      this.filters.forEach(fObj => {
        options[fObj.id] = []

        if (this.filterRefs[fObj.id]) options[fObj.id] = options[fObj.id].concat(this.filterRefs[fObj.id].options)
        // Get options for every given category
        // fObj.categories.forEach(key => {
        //   if (this.filterRefs[key]) options[fObj.id] = options[fObj.id].concat(this.filterRefs[key].options)
        // })
        // Remove duplicates
        options[fObj.id] = [...new Set(options[fObj.id])]
      })

      return options
    },
    selectedAnySome () { // Are there options deselected for any field? For use in the component button
      let anyHasSome = false

      for (const key in this.selectedAll) {
        if (!this.selectedAll[key]) {
          anyHasSome = !this.selectedAll[key]
          break
        }
      }

      return anyHasSome
    },
    selectedAll () { // For use in the 'Select all' options
      const returnObj = {}
      for (const key in this.selectedFilters) {
        this.$set(returnObj, key, (this.selectedFilters[key].length === this.filterOptions[key].length))
      }

      return returnObj
    },
    selectedSome () {
      const returnObj = {}
      for (const key in this.selectedFilters) {
        this.$set(returnObj, key, (this.selectedFilters[key].length > 0 && !this.selectedAll[key]))
      }

      return returnObj
    },
    selectedNone () {
      let noneSelected = true
      for (const key in this.selectedFilters) {
        if (this.selectedFilters[key].length > 0) {
          noneSelected = false
          break
        }
      }

      return noneSelected
    },
    icon () {
      const returnObj = {}
      for (const key in this.selectedFilters) {
        if (this.selectedAll[key]) this.$set(returnObj, key, 'check_box')
        else if (this.selectedSome[key]) this.$set(returnObj, key, 'indeterminate_check_box')
        else this.$set(returnObj, key, 'check_box_outline_blank')
      }

      return returnObj
    },
    allowedActive () {
      let isAllowed = false
      for (const key in this.filterOptions) {
        if (this.filterOptions[key].length) {
          isAllowed = true
          break
        }
      }

      return isAllowed
    }
  },
  methods: {
    ...mapActions({
      getFilterOptions: 'mapbox/socket_fetchFilterOptions'
    }),
    ...mapMutations({
      unspiderify: 'mapbox/unspiderify'
    }),
    initLabels () {
      if (this.labelsInitialized) return // No need to do it again
      for (const { id } of this.filters) {
        if (!this.selectedFilters[id] || !this.selectedFilters[id].length) return // It is already empty

        // Save the original values
        const originFilters = JSON.parse(JSON.stringify(this.selectedFilters[id]))
        // Empty the current values
        this.$set(this.selectedFilters, id, [])

        // Restore the original values after the component has become visible
        setTimeout(() => {
          this.$set(this.selectedFilters, id, originFilters)
        }, 280) // Menu animation takes 280ms to complete
      }
      this.labelsInitialized = true
    },
    updateSelectedFilters (id, selectedFilters) {
      this.$set(this.selectedFilters, id, selectedFilters)
      this.unspiderify()

      // Get the ID's of the layers on which the filter should be applied (without duplicates)
      if (!this.layerIds) {
        this.layerIds = {}
        for (const { id, categories } of this.filters) {
          this.layerIds[id] = [...new Set([].concat.apply([], categories.map(category => this.getLayerIdsByCategoryType(category))))]
        }
      }

      for (const key in this.layerIds) {
        const layerIds = this.layerIds[key]

        // No layers to apply filters on, continue
        if (!layerIds.length) continue

        // The ID's of the sources which have to be filtered
        if (!this.sourceIds) this.sourceIds = {}
        if (!this.sourceIds[key]) this.sourceIds[key] = layerIds.map(l => this.map.getLayer(l).source)

        const { id, filterBy } = this.filters.find(f => f.id === key)

        // Get unselected filter options
        const unselectedFilters = this.filterOptions[id].filter(f => {
          return this.selectedFilters[id] && !this.selectedFilters[id].includes(f.value)
        }).map(f => f.value)

        // If there are no unselected filters, remove the filter
        if (!unselectedFilters.length) {
          this.handleUnifiedFilter(layerIds, null)

          // Reset data for all sources
          if (this.sourceIds[key]) this.reloadSources(this.sourceIds[key])
          continue
        }

        // Otherwise, create filter Object
        const mapFilter = unselectedFilters.map(f => {
          const filterObj = {}
          filterObj[filterBy] = f
          return filterObj
        })

        this.handleUnifiedFilter(layerIds, mapFilter)
      }
    },
    toggleFilters (id) {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAll[id]) newValue = []
        else newValue = this.filterOptions[id].map(o => o.value)

        this.updateSelectedFilters(id, newValue)
      })
    }
  },
  created () {
    /**
     * Set initially 'visible' options as selected on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch('layersPending', (layersPending) => {
      if (!this.initialized && !layersPending) {
        this.setDataPendingState(true)

        const promises = this.filters.map(fObj => this.getFilterOptions({ id: fObj.id, categories: fObj.categories, filterBy: fObj.filterBy }))

        // Get filter options and select them all on init
        Promise.all(promises)
          .then(() => {
            for (const id in this.filterOptions) {
              this.$set(this.selectedFilters, id, this.filterOptions[id].map(f => f.value))
            }

            this.initialized = true

            // Unwatch after init
            if (this.unwatchLayers) this.unwatchLayers()
            else console.error('unable to unwatch \'layersPending\'')

            this.setDataPendingState(false)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }, {
      deep: true
    })
  }
}
</script>
